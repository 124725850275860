import * as React from 'react';
import {faExclamationTriangle} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {ContentModal} from '../../ui/modals/ContentModal';
import {LinkButton} from '../../components/LinkButton/LinkButton';
import {OrderHeader} from '../order-header.class';
import {TableCards} from '../../ui/Tables/TableCards';
import {TemplateHelpers} from '../../tools/template-helpers.class';
import {User} from '../../users/user.class';

interface OpenOrdersModalProps {
    onClose: () => void;
    onSelectOrder: (orderOption: string) => void;
    orderHeaders: OrderHeader[];
    show: boolean;
    user: User;
}

export const OpenOrdersModal = ({onClose, onSelectOrder, orderHeaders, show, user}: OpenOrdersModalProps) => {
    /**
     * Closes modal without selecting an order
     */
    const cancelOrderSelection = () => {
        onSelectOrder(``);
        onClose();
    };

    /**
     * Template
     */
    return (
        <ContentModal
            onClose={cancelOrderSelection}
            footer={
                <button
                    className="btn-modal-action red"
                    data-e2e="manageYourOrderPopup-CreateOrderBtn"
                    onClick={() => {
                        onSelectOrder(`createNewOrder`);
                        onClose();
                    }}
                >
                    Create New Order
                </button>
            }
            show={show}
            title="Manage Your Orders"
        >
            <>
                <h4>Edit an existing order</h4>
                <TableCards
                    columns={[
                        {
                            title: 'Order',
                            customFormatter: (orderHeader: OrderHeader) => (
                                <>
                                    <LinkButton
                                        text={orderHeader.ordrNbr}
                                        onClick={() => {
                                            onSelectOrder(orderHeader.ordrNbr);
                                            onClose();
                                        }}
                                    />
                                    {orderHeader.status === `Order Declined` && (
                                        <>
                                            <br />
                                            <span className="error">
                                                <FontAwesomeIcon icon={faExclamationTriangle} />
                                                Declined
                                            </span>
                                        </>
                                    )}
                                </>
                            ),
                        },
                        {
                            title: 'Order Date',
                            customFormatter: (orderHeader: OrderHeader) => TemplateHelpers.formatDate(orderHeader.ordrDt),
                        },
                        {
                            title: 'PO',
                            maskPII: true,
                            fieldName: 'po',
                        },
                        {
                            title: 'Location',
                            maskPII: true,
                            fieldName: 'ordLocation',
                        },
                        {
                            title: 'Attention',
                            maskPII: true,
                            fieldName: 'attn',
                        },
                        {
                            title: 'City',
                            fieldName: 'city',
                        },
                        {
                            title: 'State',
                            fieldName: 'st',
                        },
                        {
                            title: 'Amount',
                            hidden: !user.canViewPrice(),
                            rightAlign: true,
                            customFormatter: (orderHeader: OrderHeader) => TemplateHelpers.formatCurrency(orderHeader.amount),
                        },
                    ]}
                    data={orderHeaders}
                />
            </>
        </ContentModal>
    );
};
